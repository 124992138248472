import axios, { AxiosResponse } from 'axios';
import {
  BaseCheckupUrl,
  ChangePasswordRequest,
  CheckupRequest,
  CheckupRequestForPet,
  CheckupRequestInfo,
  CheckUpRequestResponse,
  CheckupRequestStatusResponse,
  CheckupsForPetWithSurveyAnswer,
  CreateCheckupRequestForPetResult,
  ItemPlaybackUrl,
  ItemReviewAnswerRequest,
  ItemToReview,
  PetAndClinicInfo,
  PetCheckupStats,
  PetInfo,
  PetNameChange,
  SurveyAnswersFull,
  SurveyAnswersRequest,
  SurveyAnswersUpdateRequest,
  SurveyFull,
  SurveyInfo,
  UserAccount,
  UserLogin,
  UserLoginResponse,
  VideoUploadRequest
} from '@companion-professional/dctypes';
import { authAxios } from './authAxios';
import {
  CHECKUP_REQUESTS_URL,
  CHECKUP_REQUESTS_FOR_PET_URL,
  CHECKUP_REQUEST_URL,
  LOGIN_URL,
  UPDATE_PET_INFO_URL,
  GET_PLAYBACK_URL,
  GET_PETS_URL,
  GET_PET_URL,
  CHANGE_PASSWORD_URL,
  GET_USER_ACCOUNTS_URL,
  CHANGE_LOGIN_CLINIC_URL,
  GET_SURVEYS_FOR_CLINIC_URL,
  GET_FULL_SURVEY_URL,
  ADD_SURVEY_ANSWERS_URL,
  GET_BASE_CHECKUP_URL,
  GET_SURVEY_ANSWERS_URL,
  UPDATE_PET_NAME_URL,
  GET_ITEMS_TO_REVIEW_FOR_SURVEY_URL,
  GET_ITEM_REVIEW_SURVEYS_URL,
  ADD_ITEM_REVIEW_ANSWERS_URL,
  UPDATE_SURVEY_ANSWERS_URL,
  ADD_CHECKUP_REQUEST_FOR_PET_URL,
  GET_ITEMS_TO_REVIEW_CHECKUP_SURVEYS_URL,
  ARCHIVE_CHECKUP_REQUEST_URL,
  GET_PET_STATS_URL,
  ARCHIVE_PET_URL
} from './config';
import { DynamicFormQuestionField } from '@companion-professional/components';

// login is a function that authenticates a user with the provided email and password.  It returns an auth token that
// can be used to make authenticated requests.
export const login = async (email: string, password: string): Promise<string> => {
  const response = await axios.post<UserLoginResponse, AxiosResponse<UserLoginResponse>, UserLogin>(LOGIN_URL, {
    email,
    password
  });

  return response.data.token;
};

// changePassword is a function that changes the password for the currently logged-in user.  This returns a new auth
// token that should replace the previous token (the new token should replace the "must update password" token) and
// can then be used to make authenticated requests.
export const changePassword = async (oldPassword: string, newPassword: string): Promise<string> => {
  const response = await authAxios.post<UserLoginResponse, AxiosResponse<UserLoginResponse>, ChangePasswordRequest>(
    CHANGE_PASSWORD_URL,
    {
      old_password: oldPassword,
      new_password: newPassword
    }
  );

  return response.data.token;
};

// changeClinicLogin contains the API request that changes the clinic login for the currently logged-in user.  This
// returns a new auth token that should replace the previous token and can then be used to make authenticated requests.
export const changeClinicLogin = async (clinicId: string): Promise<string> => {
  const response = await authAxios.post<UserLoginResponse, AxiosResponse<UserLoginResponse>, UserLogin>(
    `${CHANGE_LOGIN_CLINIC_URL}/${clinicId}`
  );

  return response.data.token;
};

// getActiveCheckups is a function that fetches the active checkups for the currently logged-in user.
export const getActiveCheckups = async (): Promise<CheckupRequestStatusResponse[]> => {
  const response = await authAxios.get<CheckupRequestStatusResponse[]>(CHECKUP_REQUESTS_URL);
  return response.data;
};

// getCheckupsForPet is a function that fetches the active checkups for the provided petId.
export const getCheckupsForPet = async (petId: string): Promise<CheckupRequestStatusResponse[]> => {
  const response = await authAxios.get<CheckupRequestStatusResponse[]>(`${CHECKUP_REQUESTS_FOR_PET_URL}/${petId}`);
  return response.data;
};

// getUnassignedCheckupsForPet is a function that fetches the checkups that have not been assigned to the given survey
// for the given pet.
export const getUnassignedCheckupsForPet = async (
  petId: string,
  surveyId: string
): Promise<CheckupsForPetWithSurveyAnswer[]> => {
  const response = await authAxios.get<CheckupsForPetWithSurveyAnswer[]>(
    `${CHECKUP_REQUESTS_FOR_PET_URL}/${petId}/unassigned/${surveyId}`
  );
  return response.data;
};

// getCheckupRequest is a function that fetches a single checkup request.
export const getCheckupRequest = async (checkupRequestId: string): Promise<CheckupRequestInfo> => {
  const response = await authAxios.get<CheckupRequestInfo>(`${CHECKUP_REQUEST_URL}/${checkupRequestId}`);
  return response.data;
};

// archiveCheckupRequest is a function that archives a checkup request.
export const archiveCheckupRequest = async (checkupRequestId: string): Promise<void> => {
  await authAxios.delete(`${ARCHIVE_CHECKUP_REQUEST_URL}/${checkupRequestId}`);
  return;
};

// checkupRequestStatus is a function creates a new checkup request for the provided clinicPetId.
export const createQuickCheckupRequest = async (
  clinicPetId: string,
  requests: VideoUploadRequest[]
): Promise<CheckUpRequestResponse> => {
  const response = await authAxios.post<CheckUpRequestResponse, AxiosResponse<CheckUpRequestResponse>, CheckupRequest>(
    CHECKUP_REQUEST_URL,
    { clinic_pet_id: clinicPetId, requests }
  );
  return response.data;
};

export const createCheckupRequestForPet = async (
  petId: string,
  requests: VideoUploadRequest[],
  answersId?: string
): Promise<CreateCheckupRequestForPetResult> => {
  const response = await authAxios.post<
    CreateCheckupRequestForPetResult,
    AxiosResponse<CreateCheckupRequestForPetResult>,
    CheckupRequestForPet
  >(ADD_CHECKUP_REQUEST_FOR_PET_URL, { pet_id: petId, answers_id: answersId, requests });
  return response.data;
};

// getPlaybackUrl is a function that fetches the playback url for a checkup request item.
export const getPlaybackUrl = async (itemId: string): Promise<ItemPlaybackUrl> => {
  const response = await authAxios.get<ItemPlaybackUrl>(`${GET_PLAYBACK_URL}/${itemId}`);
  return response.data;
};

// updatePetInfo updates pet info (currently the name and clinic pet id).
export const updatePetInfo = async (petId: string, petName: string, clinicPetId: string): Promise<void> => {
  await authAxios.post<{}, AxiosResponse<{}>, PetInfo>(UPDATE_PET_INFO_URL, {
    id: petId,
    name: petName,
    clinic_pet_id: clinicPetId,
    owner: []
  });
  return;
};

// updatePetName updates pet name
export const updatePetName = async (petId: string, petName: string): Promise<void> => {
  await authAxios.post<{}, AxiosResponse<{}>, PetNameChange>(UPDATE_PET_NAME_URL, {
    pet_id: petId,
    new_name: petName
  });
  return;
};

// getPet is a function that fetches a single pet using the provided petId.
export const getPet = async (petId: string): Promise<PetAndClinicInfo> => {
  const response = await authAxios.get<PetAndClinicInfo>(`${GET_PET_URL}/${petId}`);
  return response.data;
};

// archivePetAndCheckups archives a pet and all the associated checkups.
export const archivePetAndCheckups = async (petId: string): Promise<PetAndClinicInfo> => {
  const response = await authAxios.delete<PetAndClinicInfo>(`${ARCHIVE_PET_URL}/${petId}`);
  return response.data;
};

// getPets is a function that fetches the pets for the currently logged-in user (i.e all the pets associated with the
// user's clinic).
export const getPets = async (): Promise<PetAndClinicInfo[]> => {
  const response = await authAxios.get<PetAndClinicInfo[]>(`${GET_PETS_URL}`);
  return response.data;
};

// getPetStats fetches pet checkup stats for the provided petId.  This includes the number of checkups, the number of
// items uploaded, and the number of surveys answered.
export const getPetStats = async (petId: string): Promise<PetCheckupStats> => {
  const response = await authAxios.get<PetCheckupStats>(`${GET_PET_STATS_URL}/${petId}`);
  return response.data;
};

// getUserAccounts fetches all the accounts for the currently logged-in user.
export const getUserAccounts = async (): Promise<UserAccount[]> => {
  const response = await authAxios.get<UserAccount[]>(`${GET_USER_ACCOUNTS_URL}`);
  return response.data;
};

// getSurveysForClinic fetches all the surveys that are available for the currently logged-in user.
export const getSurveysForClinic = async (): Promise<SurveyInfo[]> => {
  const response = await authAxios.get<SurveyInfo[]>(`${GET_SURVEYS_FOR_CLINIC_URL}`);
  return response.data;
};

// getAllSurveys fetches all the surveys that are available in the system.  Note that this is only available to
// administrators.
export const getAllSurveysForCheckups = async (): Promise<SurveyInfo[]> => {
  const response = await authAxios.get<SurveyInfo[]>(`${GET_ITEMS_TO_REVIEW_CHECKUP_SURVEYS_URL}`);
  return response.data;
};

// getSurvey fetches the complete survey for the provided surveyId and version.  If version is not provided (or is 0),
// the latest version of the survey will be retrieved. This includes the questions.
export const getSurvey = async (surveyId: string, version?: number): Promise<SurveyFull<DynamicFormQuestionField>> => {
  let url = `${GET_FULL_SURVEY_URL}/${surveyId}`;

  if (version) {
    url = `${url}/${version}`;
  }

  const response = await authAxios.get<SurveyFull<DynamicFormQuestionField>>(url);
  return response.data;
};

// getCheckupSurveyForItemReview fetches the complete survey for the provided surveyId and version.  This will return a
// checkup survey (if it exists) regardless of what clinic the user is longed in as, but will only do so for a user
// with the admin role.  If version is not provided (or is 0),the latest version of the survey will be retrieved. This
// includes the questions.
export const getCheckupSurveyForItemReview = async (
  surveyId: string,
  version?: number
): Promise<SurveyFull<DynamicFormQuestionField>> => {
  let url = `${GET_ITEMS_TO_REVIEW_CHECKUP_SURVEYS_URL}/${surveyId}`;

  if (version) {
    url = `${url}/${version}`;
  }

  const response = await authAxios.get<SurveyFull<DynamicFormQuestionField>>(url);
  return response.data;
};

// addSurveyAnswers submits the answers for a survey.
export const addSurveyAnswers = async (
  surveyId: string,
  surveyVersion: number,
  checkupRequestIds: string[],
  answers: Record<string, unknown>
): Promise<void> => {
  await authAxios.post<{}, AxiosResponse<{}>, SurveyAnswersRequest>(ADD_SURVEY_ANSWERS_URL, {
    survey_id: surveyId,
    version: surveyVersion,
    checkup_request_ids: checkupRequestIds,
    answers
  });
  return;
};

//
export const updateSurveyAnswers = async (
  surveyId: string,
  version: number,
  answersId: string,
  answers: Record<string, unknown>,
  checkupsToAdd: string[] = []
): Promise<void> => {
  await authAxios.put<{}, AxiosResponse<{}>, SurveyAnswersUpdateRequest>(UPDATE_SURVEY_ANSWERS_URL, {
    survey_id: surveyId,
    version,
    answers_id: answersId,
    answers,
    checkups_to_add: checkupsToAdd
  });
  return;
};

// getBaseCheckupUrl fetches the base checkup url that will be used to create the checkup request URLs.
export const getBaseCheckupUrl = async (): Promise<BaseCheckupUrl> => {
  const response = await authAxios.get<BaseCheckupUrl>(`${GET_BASE_CHECKUP_URL}`);
  return response.data;
};

export const getSurveyAnswers = async (answersId: string): Promise<SurveyAnswersFull> => {
  const response = await authAxios.get<SurveyAnswersFull>(`${GET_SURVEY_ANSWERS_URL}/${answersId}`);
  return response.data;
};

export const getItemsToReviewForSurvey = async (
  itemSurveyId: string,
  checkupSurveyId: string
): Promise<ItemToReview[]> => {
  const response = await authAxios.get<ItemToReview[]>(
    `${GET_ITEMS_TO_REVIEW_FOR_SURVEY_URL}/${itemSurveyId}/checkup_survey/${checkupSurveyId}`
  );
  return response.data;
};

// getItemReviewSurveys fetches the latest versions of all the item review surveys.
export const getItemReviewSurveys = async (): Promise<SurveyInfo[]> => {
  const response = await authAxios.get<SurveyInfo[]>(GET_ITEM_REVIEW_SURVEYS_URL);
  return response.data;
};

// getItemReviewSurvey fetches the complete item review survey (including questions) for the provided surveyId and version.
export const getItemReviewSurvey = async (surveyId: string): Promise<SurveyFull<DynamicFormQuestionField>> => {
  const response = await authAxios.get<SurveyFull<DynamicFormQuestionField>>(
    `${GET_ITEM_REVIEW_SURVEYS_URL}/${surveyId}`
  );
  return response.data;
};

export const addItemReviewAnswers = async (
  surveyId: string,
  surveyVersion: number,
  itemId: string,
  answers: Record<string, unknown>
) => {
  await authAxios.post<{}, AxiosResponse<{}>, ItemReviewAnswerRequest>(ADD_ITEM_REVIEW_ANSWERS_URL, {
    survey_id: surveyId,
    version: surveyVersion,
    item_id: itemId,
    answers
  });
  return;
};
