import axios from 'axios';
import { useAuthStore } from '../state/auth';

// authAxios is an axios instance that is configured to automatically add the auth token to the request headers.  It
// uses the auth token from the auth state store.
export const authAxios = axios.create({
  // NOTE: if we wanted to use a different base URL for the API, we could set it here. (e.g.
  // https://api.companionpro.biz)
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json'
  }
});

authAxios.interceptors.request.use(
  (config) => {
    const authToken = useAuthStore.getState().token;
    if (!authToken) {
      throw new Error('No auth token provided');
    }

    config.headers.Authorization = `Bearer ${authToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
