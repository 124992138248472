import React, { FunctionComponent } from 'react';
import { Button, Card } from '@companion-professional/components';
import { CheckupUpCardContent } from './CheckupUpCardContent';
import { ExistingCheckupDialog } from '../ExistingCheckupDialog';

interface AddCheckupCardProps {
  petId: string;
  surveyId?: string;
  addCheckupId: (newCheckupId: string) => void;
  allCheckupIds?: string[];
}

// AddCheckupCard is a card that allows the user to add an existing checkup to set of survey answers.
export const AddCheckupCard: FunctionComponent<AddCheckupCardProps> = ({
  petId,
  addCheckupId,
  surveyId,
  allCheckupIds = []
}) => {
  return (
    <Card className="min-h-80">
      <CheckupUpCardContent>
        <div className="flex flex-col space-y-4">
          <ExistingCheckupDialog
            surveyId={surveyId}
            petId={petId}
            onAddExistingCheckup={(cid) => {
              addCheckupId(cid);
            }}
            allCheckupIds={allCheckupIds}
          >
            <Button variant="primary" size="small">
              Add Existing Checkup
            </Button>
          </ExistingCheckupDialog>
        </div>
      </CheckupUpCardContent>
    </Card>
  );
};
