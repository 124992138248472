import * as React from 'react';
import { cn } from '@companion-professional/webutils';

// This directory contains the styled components for a simple card (a container with a header, content, and footer).
//
// The common structure of the card is:
//   <Card>
//     <CardHeader>
//       <CardTitle>...</CardTitle>
//       <CardDescription>...</CardDescription>
//     </CardHeader>
//     <CardContent>...</CardContent>
//     <CardFooter>...</CardFooter>
//   </Card>

export { CardContent } from './CardContent';
export { CardDescription } from './CardDescription';
export { CardFooter } from './CardFooter';
export { CardHeader } from './CardHeader';
export { CardTitle } from './CardTitle';

export const Card = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'bg-card text-card-foreground flex flex-col justify-between rounded-lg border shadow-sm ',
        className
      )}
      {...props}
    />
  )
);
Card.displayName = 'Card';
