import React, { FunctionComponent, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Sparkles } from 'lucide-react';
import * as Sentry from '@sentry/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { toast } from '@companion-professional/components';
import { AuthRoles } from '@companion-professional/dctypes';
import { AppLayout } from './components/AppLayout';
import {
  Dashboard,
  PageNotFound,
  PetParents,
  Pets,
  Settings,
  Profile,
  Checkup,
  GenerateError,
  MaintenanceMode
} from './pages';
import { FeatureFlags } from './types/featureFlags';
import { Reviews } from './pages/Reviews';
import { RoleProtectedRoutes } from './components/RoleProtectedRoutes';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

// This flag is used to determine if the site was in maintenance mode on the last render. If it was, then we can show a
// toast to let the user know that the site has been updated.  This is in the module scope so that changing it won't cause
// a re-render.
let wasInMaintenanceMode = false;

// These are all the routes that are available to a logged-in user.
export const LoggedInRoutes: FunctionComponent = () => {
  const flags = useFlags<FeatureFlags>();

  useEffect(() => {
    if (wasInMaintenanceMode && !flags?.siteInMaintenanceMode) {
      // When the flag switches from true to false, show a toast to let the user know that the site has been updated,
      // and they should refresh their browser.
      wasInMaintenanceMode = false;
      toast('New Site Version', {
        description: `This app was recently updated. Please refresh your browser to get the latest version.`,
        icon: <Sparkles />,
        duration: Infinity,
        position: 'top-center'
      });
    }
  }, [flags?.siteInMaintenanceMode]);

  if (flags?.siteInMaintenanceMode) {
    // If the site is in maintenance mode, then we don't want to show the normal app.  Instead, we show the
    // MaintenanceMode page.
    wasInMaintenanceMode = true;
    return <MaintenanceMode />;
  }

  return (
    <AppLayout>
      <SentryRoutes>
        <Route index element={<Dashboard />} />
        <Route path="/pet-parents/*" element={<PetParents />} />
        <Route path="/pets/*" element={<Pets />} />
        <Route path="/checkup/:checkupId" element={<Checkup />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/generate-error" element={<GenerateError />} />
        <Route element={<RoleProtectedRoutes allowRoles={[AuthRoles.global_admin]} redirectPath="/" />}>
          <Route path="/reviews/*" element={<Reviews />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </SentryRoutes>
    </AppLayout>
  );
};
