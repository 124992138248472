import React, { FunctionComponent, useMemo } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ArrowLeft, Archive, VideoOff, Printer, PrinterCheck } from 'lucide-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  buttonVariants,
  IconTextButton,
  QueryLoaderWrapper,
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@companion-professional/components';
import { Page } from '../../components/Page';
import { Section } from '../../components/Section';
import { useCheckupRequest } from '../../hooks/useCheckupRequest';
import { CheckupLinkButtons } from '../../components/CheckupLinkButtons';
import { ArchiveCheckupDialog } from '../../components/ArchiveCheckupDialog';
import { VideoTypeContainer } from '../../components/VideoTypeContainer';
import { PageNotFound } from '../PageNotFound';
import { CheckupSurveys } from './CheckupSurveys';
import { CheckupPrinterDialog } from '../../components/CheckupPrinterDialog';
import { cn } from '@companion-professional/webutils';
import { FeatureFlags } from '../../types/featureFlags';
import { EventCardsPrinterDialog } from '../../components/EventCardsPrinterDialog';

type CheckupParams = {
  checkupId: string;
  petId?: string;
};

// Checkup is a page that shows the details of a checkup request.  This might include videos, images, and/or
// other data.  It also shows the surveys that are associated with the checkup.
export const Checkup: FunctionComponent = () => {
  const navigate = useNavigate();
  const flags = useFlags<FeatureFlags>();

  const { checkupId, petId } = useParams<CheckupParams>();
  if (!checkupId) {
    return <PageNotFound />;
  }

  const { isPending, checkupRequest, error } = useCheckupRequest(checkupId);
  const canArchiveCheckup = useMemo(() => {
    if (!checkupRequest) {
      return false;
    }

    return !checkupRequest.answered_surveys?.length && checkupRequest.num_asset_ids === 0;
  }, [checkupRequest]);

  // Determine where to go back to based on whether we have a petId (in the params of the URL).  If this page was
  // browsed to from a pet page, we want to go back to that pet page.  Otherwise, we want to go back to the dashboard.
  const returnUrl = petId ? `/pets/${petId}` : '/';

  return (
    <Page title="Checkup" wrapWithSection={false}>
      <QueryLoaderWrapper isPending={isPending} error={error} errorHeading="Error loading checkup request">
        {checkupRequest ? (
          <Section className="space-y-4 py-4">
            <div className="flex min-h-96 flex-col items-center space-x-4 space-y-4 lg:flex-row lg:items-start">
              {checkupRequest.requests.length > 0 ? (
                <div className="max-w-xl flex-1">
                  {checkupRequest?.requests.map((item) => (
                    <div key={`${checkupRequest.id}-${item.item_id}`}>
                      {/* TODO: Support other item types (i.e. images) but for now, just assume all types are video */}
                      {item.upload_count > 0 ? (
                        <VideoTypeContainer videoItemId={item.item_id} />
                      ) : (
                        <div className="my-8 flex flex-col items-center justify-center gap-4 text-destructive">
                          <VideoOff size="36" />
                          <div className="font-semibold">Checkup video not uploaded</div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <Alert>
                  <AlertTitle>No Requests Found</AlertTitle>
                  <AlertDescription></AlertDescription>
                </Alert>
              )}
              <div className="flex-1 rounded-md border border-gray-500 p-4">
                <div className="grid min-w-96 flex-1 grid-cols-2 gap-2">
                  <div className="text-gray-600">Date Requested</div>
                  <div>{`${checkupRequest.vet_first_name} ${checkupRequest.vet_last_name}`.trim()}</div>
                  <div className="text-gray-600">Pet Id</div>
                  <div>{checkupRequest.clinic_pet_id}</div>
                  <div className="text-gray-600">Pet Name</div>
                  <div>
                    <Link to={`/pets/${checkupRequest.pet_id}`}>
                      <span>{checkupRequest.pet_name}</span>
                    </Link>
                  </div>
                </div>
                <CheckupSurveys checkupRequest={checkupRequest} />
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <div className="flex flex-row justify-start gap-2">
                {flags.printEventCards ? (
                  <Tooltip>
                    <TooltipTrigger>
                      <EventCardsPrinterDialog checkupRequest={checkupRequest}>
                        <div className={cn(buttonVariants({ variant: 'primary', size: 'small' }), 'h-10')}>
                          <PrinterCheck size="20" className="mr-2" /> Event Cards
                        </div>
                      </EventCardsPrinterDialog>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Print Event Cards</p>
                      <p>Opens a dialog were you can print the cards used at a Companion event.</p>
                    </TooltipContent>
                  </Tooltip>
                ) : null}
                {flags.printAtHomeCheckupCards ? (
                  <Tooltip>
                    <TooltipTrigger>
                      <CheckupPrinterDialog checkupRequest={checkupRequest}>
                        <div className={cn(buttonVariants({ variant: 'primary', size: 'small' }), 'h-10')}>
                          <Printer size="20" className="mr-2" /> Reminder Card
                        </div>
                      </CheckupPrinterDialog>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Print Checkup Reminder Card</p>
                      <p>Opens a dialog were you can print a checkup reminder card with the QR code.</p>
                    </TooltipContent>
                  </Tooltip>
                ) : null}
              </div>
              <div className="flex items-end justify-end gap-2">
                {/* TODO: Add tooltip to help with explaining why the button is disabled.  The current tooltip component
                   doesn't visible work well with the Dialog trigger and the Button component.  We should update the
                   current tooltip component or look into creating or using a better option. */}
                <ArchiveCheckupDialog checkupRequest={checkupRequest} returnUrl={returnUrl}>
                  <Button
                    size="small"
                    variant="destructive"
                    type="button"
                    disabled={!canArchiveCheckup}
                    className="h-10"
                  >
                    <Archive size="20" className="mr-2" /> Archive Checkup
                  </Button>
                </ArchiveCheckupDialog>
                <CheckupLinkButtons checkupRequest={checkupRequest} />
              </div>
            </div>
          </Section>
        ) : null}
      </QueryLoaderWrapper>

      <div className="mt-4">
        <IconTextButton
          size="small"
          variant="secondary"
          onClick={() => {
            navigate(returnUrl);
          }}
          Icon={ArrowLeft}
        >
          {petId
            ? !isPending && checkupRequest?.pet_name
              ? `Back To ${checkupRequest.pet_name}`
              : 'Back To Pet'
            : 'Back To Dashboard'}
        </IconTextButton>
      </div>
    </Page>
  );
};
