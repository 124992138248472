import React, { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ReviewsDashboard } from './ReviewsDashboard';
import { ReviewRoutes } from './ReviewRoutes';

// Reviews is a page that allows for the review of checkup videos.
export const Reviews: FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/items-to-review/:checkupSurveyId/:itemReviewSurveyId/*" element={<ReviewRoutes />} />
      <Route path="*" element={<ReviewsDashboard />} />
    </Routes>
  );
};
