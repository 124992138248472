import React, { FunctionComponent, useMemo, useState } from 'react';
import { ArrowLeft, CheckCircle2 } from 'lucide-react';
import { PetAndClinicInfo, SurveyAnswersFull, SurveyFull } from '@companion-professional/dctypes';
import {
  DynamicForm,
  DynamicFormProps,
  DynamicFormQuestionField,
  IconTextButton,
  QueryLoaderWrapper
} from '@companion-professional/components';
import { useSurvey } from '../../hooks/useSurvey';
import { CheckupCard, AddCheckupCard } from '../CheckupCard';
import { Section } from '../Section';
import { useNavigate } from 'react-router-dom';

interface SurveyDisplayProps extends DynamicFormProps {
  // surveyId is the id of the survey to display.
  surveyId: string;

  // pet is the pet that the survey is for
  pet: PetAndClinicInfo;

  // surveyAnswers is the answers for the survey.  If this is provided, the survey will be displayed with the answers
  // filled in.
  surveyAnswers?: SurveyAnswersFull;

  // checkupRequestIds is a list of checkup request ids that are associated with this survey.
  checkupRequestIds?: string[];

  // answersSurveyVersion is the version of the survey that the answers are for.  If provided, that version of the
  // survey will be loaded.  If not provided, the latest version of the survey will be retrieved.
  answersSurveyVersion?: number;

  // surveySaved is a flag to indicate that the survey has been saved.  If true, a success message will be displayed.
  surveySaved?: boolean;

  // onSurveySubmit is a function that is called when the survey is submitted.  It is passed the form data and the
  // loaded survey.
  onSurveySubmit?: (
    formData: Record<string, unknown>,
    survey: SurveyFull<DynamicFormQuestionField>,
    checkupsToAdd?: string[]
  ) => void;
}

// SurveyDisplay displays a survey with the given surveyId.  It also takes in all the props for DynamicForm and passes
// them into that component (so you can pass in extra parameters like answers, showSubmitButton, etc).
export const SurveyDisplay: FunctionComponent<SurveyDisplayProps> = ({
  surveyId,
  pet,
  surveyAnswers,
  checkupRequestIds = [],
  surveySaved = false,
  onSurveySubmit = () => {},
  answersSurveyVersion,
  ...props
}) => {
  const navigate = useNavigate();
  const [checkupsToAddIds, setCheckupsToAddIds] = useState<string[]>([]);

  // This fetches a survey with the given surveyId and version of the answers (if provided).  In the future (and if we
  // want to add the feature), we can use this to assign the answers to a different version of the survey.
  // New answers will not have an answersSurveyVersion, so they will be assigned to the latest version of the survey.
  const { survey, error, isPending } = useSurvey(surveyId, answersSurveyVersion);

  const surveyTitle = useMemo(
    () => (survey?.title && survey.title.length > 0 ? survey.title : survey?.name),
    [survey?.title, survey?.name]
  );

  return (
    <QueryLoaderWrapper isPending={isPending} error={error}>
      <div className="my-6">
        <div className="flex flex-row items-start justify-between gap-x-1">
          <div className="text-lg font-semibold">{surveyTitle}</div>
          <div className="aspect-square rounded-lg bg-secondary p-2 text-sm font-semibold text-secondary-foreground">
            v{survey?.version}
          </div>
        </div>
        <p className="text-widget-foreground">{survey?.description}</p>
      </div>
      {surveySaved ? (
        <Section>
          <div className="flex flex-col items-center p-4">
            <div className="mb-4 text-lg font-bold text-primary">{surveyTitle} has been saved.</div>
            <CheckCircle2 size={48} className="text-primary" />
          </div>
        </Section>
      ) : (
        <div className="space-y-4">
          <Section className="py-4">
            <h2 className="mb-4 text-lg font-bold">Selected Checkups</h2>
            <div className="grid grid-cols-3 gap-2">
              {checkupRequestIds.map((cid) => (
                <CheckupCard key={`checkup-card-${cid}-${surveyId}`} checkupRequestId={cid} />
              ))}
              {checkupsToAddIds.map((cid) => (
                <CheckupCard key={`checkup-to-add-card-${cid}-${surveyId}`} checkupRequestId={cid} isDirty />
              ))}
              <AddCheckupCard
                petId={pet.id}
                surveyId={surveyId}
                allCheckupIds={[...checkupRequestIds, ...checkupsToAddIds]}
                addCheckupId={(cid) => {
                  setCheckupsToAddIds([...checkupsToAddIds, cid]);
                }}
              />
            </div>
          </Section>
          <Section className="py-4">
            <DynamicForm
              fields={survey?.questions || []}
              {...props}
              onSubmit={(formData) => {
                if (survey) {
                  onSurveySubmit(formData, survey, checkupsToAddIds);
                }
              }}
            />
          </Section>
          <div>
            <IconTextButton
              size="small"
              variant="secondary"
              onClick={() => {
                navigate(`/pets/${pet.id}`);
              }}
              Icon={ArrowLeft}
            >
              Back To {pet.name}
            </IconTextButton>
          </div>
        </div>
      )}
    </QueryLoaderWrapper>
  );
};
