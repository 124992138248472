import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CheckupsForPetWithSurveyAnswer } from '@companion-professional/dctypes';
import { getUnassignedCheckupsForPet } from '../lib/api';

// useUnassignedCheckupsForPet is a hook that fetches the checkups that have not been assigned to the given survey for
// the given pet.
// This returns the normal react-query values: isPending, error, data (checkups), as well as an invalidate function
// that can be called to invalidate the query.
export const useCheckupsForPetWithoutSurveyAnswers = (petId: string, surveyId: string) => {
  const queryKey = ['unassigned-pet-checkups', petId, surveyId];

  const {
    isPending,
    error,
    data: checkups
  } = useQuery<CheckupsForPetWithSurveyAnswer[]>({
    queryKey,
    queryFn: () => getUnassignedCheckupsForPet(petId, surveyId)
  });

  const queryClient = useQueryClient();

  return {
    isPending,
    error,
    checkups,
    invalidate: async () => {
      await queryClient.invalidateQueries({ queryKey });
    }
  };
};
