import React, { FunctionComponent } from 'react';
import { Toaster } from '@companion-professional/components';
import { Header } from '../Header';
import { ContentWrapper } from './ContentWrapper';
import { SideNav } from '../SideNav';

interface AppLayoutProps {
  children?: React.ReactNode;
}

// AppLayout is the core layout wrapper for this app (after login).  This includes the header component and a component
// that wraps the main content area.
export const AppLayout: FunctionComponent<AppLayoutProps> = ({ children }) => {
  return (
    <div className="flex h-screen flex-row md:flex">
      <SideNav />
      <div className="flex flex-1 flex-col">
        <Header />
        <ContentWrapper>{children}</ContentWrapper>
      </div>
      <Toaster />
    </div>
  );
};
