import React, { FunctionComponent } from 'react';
import { VideoOff } from 'lucide-react';
import { cn } from '@companion-professional/webutils';
import { useCheckupRequest } from '../../hooks/useCheckupRequest';
import { Card, CardFooter, CardHeader, LoaderSpinner } from '@companion-professional/components';
import { format, parseISO } from 'date-fns';
import { CheckupLinkButtons } from '../CheckupLinkButtons';
import { CheckupUpCardContent } from './CheckupUpCardContent';
import { VideoTypeContainer } from '../VideoTypeContainer';

interface CheckupCardProps {
  checkupRequestId: string;

  // showCheckupLinkButtons is a boolean that determines whether the CheckupLinkButtons should be displayed. If true,
  // the QR code and link buttons will be displayed. Defaults to true.
  showCheckupLinkButtons?: boolean;

  isDirty?: boolean;
}

export * from './AddCheckupCard';

export const CheckupCard: FunctionComponent<CheckupCardProps> = ({
  checkupRequestId,
  showCheckupLinkButtons = true,
  isDirty = false
}) => {
  const { checkupRequest, error, isPending } = useCheckupRequest(checkupRequestId);
  if (isPending || !checkupRequest) {
    return (
      <Card>
        <div className="flex min-h-36 flex-col items-center justify-center">
          <LoaderSpinner size="medium" />
        </div>
      </Card>
    );
  }

  return (
    <Card
      className={cn('min-h-90', {
        'bg-gray-200': isDirty
      })}
    >
      <CardHeader className="font-semibold">
        {format(parseISO(checkupRequest.request_created), 'MM/dd/yyyy hh:mm a')}
      </CardHeader>
      <CheckupUpCardContent>
        {error && <div>Error: {error.message}</div>}
        {checkupRequest.requests.map((item) => (
          <div key={`card-${checkupRequest.id}-${item.item_id}`}>
            {item.upload_count > 0 ? (
              <VideoTypeContainer videoItemId={item.item_id} />
            ) : (
              <div className="my-8 flex flex-col items-center justify-center gap-4 text-destructive">
                <VideoOff size="36" />
                <div className="font-semibold">Checkup video not uploaded</div>
              </div>
            )}
          </div>
        ))}
      </CheckupUpCardContent>
      {showCheckupLinkButtons && checkupRequest.num_items_requested !== checkupRequest.num_asset_ids ? (
        <CardFooter className="flex flex-row items-end justify-end">
          <CheckupLinkButtons checkupRequest={checkupRequest} />
        </CardFooter>
      ) : null}
      {isDirty && <div className="flex flex-row justify-end pb-1 pr-1 text-sm">(unsaved changes)</div>}
    </Card>
  );
};
