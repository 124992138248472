import React, { FunctionComponent, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  ErrorAlert,
  QueryLoaderWrapper
} from '@companion-professional/components';
import { DialogSharedProps } from '../DialogSharedProps';
import { useCheckupsForPetWithoutSurveyAnswers } from '../../hooks/useCheckupsForPetWithoutSurveyAnswers';
import { CheckupCard } from '../CheckupCard';

interface ExistingCheckupDialogProps extends DialogSharedProps {
  // petId is the ID of the pet that will be used when retrieving checkups that have not been assigned to a survey
  petId: string;

  // surveyId is the ID of the survey that will be used when retrieving checkups.  Only checkups that have not been
  // assigned to this survey will be retrieved.
  surveyId?: string;

  // onAddExistingCheckup is a function that is called when an existing checkup is selected to be added to the survey.
  onAddExistingCheckup?: (existingCheckupId: string) => void;

  allCheckupIds?: string[];
}

// ExistingCheckupDialog is a dialog that displays a list of checkups that have not been assigned to a survey.  The user
// can select the checkups they would like to add to the survey.
export const ExistingCheckupDialog: FunctionComponent<ExistingCheckupDialogProps> = ({
  children,
  petId,
  surveyId,
  onAddExistingCheckup = () => {},
  allCheckupIds = []
}) => {
  if (!surveyId) {
    return null;
  }

  const [open, setOpen] = useState(false);
  const { checkups = [], invalidate, isPending, error } = useCheckupsForPetWithoutSurveyAnswers(petId, surveyId);
  const [currentCheckupIndex, setCurrentCheckupIndex] = useState(0);

  const filteredCheckups = checkups.filter((checkup) => !allCheckupIds.includes(checkup.id));

  const checkupRequestId = filteredCheckups[currentCheckupIndex]?.id;

  const closeDialog = () => {
    setCurrentCheckupIndex(0);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={async (openChange) => {
        await invalidate();
        setOpen(openChange);
      }}
    >
      {children ? <DialogTrigger asChild>{children}</DialogTrigger> : null}
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Unassigned Checkups</DialogTitle>
          <DialogDescription>Select the checkups to add to this survey answer</DialogDescription>
        </DialogHeader>
        <QueryLoaderWrapper isPending={isPending} error={error}>
          {filteredCheckups.length === 0 ? (
            <div className="mb-4 mt-3 italic text-gray-700">
              All existing checkups have been either assigned to another survey or have been selected to be a part of
              this survey.
            </div>
          ) : (
            <div>
              {typeof checkupRequestId === 'string' ? (
                <>
                  <CheckupCard checkupRequestId={checkupRequestId} showCheckupLinkButtons={false} />
                  <div className="mt-4 flex flex-row justify-between gap-3">
                    <div className="flex flex-1 flex-row justify-start">
                      <Button
                        size="small"
                        type="button"
                        variant="outline"
                        onClick={() => setCurrentCheckupIndex((i) => i - 1)}
                        className="w-full"
                        disabled={currentCheckupIndex <= 0}
                      >
                        ❮ Previous
                      </Button>
                    </div>
                    <div className="flex flex-1 flex-row justify-center">
                      <Button
                        size="small"
                        type="button"
                        variant="primary"
                        className="w-full"
                        onClick={() => {
                          if (checkupRequestId) {
                            onAddExistingCheckup?.(checkupRequestId);
                            closeDialog();
                          }
                        }}
                      >
                        Add to Survey
                      </Button>
                    </div>
                    <div className="flex flex-1 flex-row justify-end">
                      <Button
                        size="small"
                        type="button"
                        variant="outline"
                        onClick={() => setCurrentCheckupIndex((i) => i + 1)}
                        className="w-full"
                        disabled={currentCheckupIndex + 1 >= filteredCheckups.length}
                      >
                        Next ❯
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <ErrorAlert message="Invalid Id" title="Error" />
              )}
            </div>
          )}
        </QueryLoaderWrapper>
        <DialogFooter className="sm:justify-start">
          <Button size="small" type="button" variant="secondary" onClick={closeDialog}>
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
