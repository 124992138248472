import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Button,
  ErrorAlert,
  Label,
  QueryLoaderWrapper,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  LoaderSpinner
} from '@companion-professional/components';
import { Printer, useDymoPrinters, usePrintLabel } from '@companion-professional/dymoprinter';
import { useGetDymoLabelPreview } from '@companion-professional/dymoprinter/src/hooks/useGetDymoLabelPreview';

interface DymoPrinterFormProps {
  // createLabels is a function that returns an array of label XML strings to print.
  createLabels: () => string[];
}

// DymoPrinterForm is a form that allows the user to select a printer and print and preview labels.  This is generalized
// to work with multiple labels label types (e.g. Checkup Reminder Card, At Event Video QR Card, etc.).
export const DymoPrinterForm: FunctionComponent<DymoPrinterFormProps> = ({ createLabels }) => {
  const [previewPngs, setPreviewPngs] = useState<string[]>([]);
  const [printerToUse, setPrinterToUse] = useState<Printer | undefined>();
  const { printers, error: printersError, isPending: printersIsPending } = useDymoPrinters();
  const { printLabel, error: printLabelError, isPending: printLabelIsPending } = usePrintLabel();
  const { getPreview, isPending: getPreviewIsPending, error: getPreviewError } = useGetDymoLabelPreview();

  // If only one printer is available, set it as the printer to use.
  useEffect(() => {
    if (Array.isArray(printers)) {
      // Set the first connected printer as the printer to use.
      setPrinterToUse(printers.find((p) => p.isConnected));
    }
  }, [printers]);

  return (
    <QueryLoaderWrapper isPending={printersIsPending} error={printersError}>
      <div>
        {printLabelError ? <ErrorAlert title="Error printing label" /> : null}
        {getPreviewError ? <ErrorAlert title="Error getting preview of label(s)" /> : null}
        {Array.isArray(printers) && printers.length > 0 ? (
          <div className="my-6 flex flex-col gap-4">
            <div className="flex flex-col space-y-2">
              <Label htmlFor="printerToUse">Printer</Label>
              <Select
                name="printerToUse"
                value={printerToUse?.name}
                onValueChange={(v) => {
                  const printer = printers.find((p) => p.name === v);
                  setPrinterToUse(printer);
                }}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select a printer" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {printers.map((printer) => (
                      <SelectItem key={printer.name} value={printer.name} disabled={!printer.isConnected}>
                        {printer.name} {printer.isConnected ? '(Connected)' : '(Not Connected)'}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            {getPreviewIsPending ? (
              <div className="flex flex-col items-center justify-center text-primary">
                <LoaderSpinner size="small" />
                <div>Fetching previews...</div>
              </div>
            ) : null}
            {previewPngs.length > 0 ? (
              <div className="flex flex-col gap-2">
                {previewPngs.map((preview, i) => (
                  <img
                    key={i}
                    src={`data:image/png;base64,${preview}`}
                    alt={`Preview ${i}`}
                    className="border border-gray-400"
                  />
                ))}
              </div>
            ) : null}
            <div className="flex flex-row gap-2">
              <Button
                size="small"
                type="button"
                variant="primary"
                className="w-full"
                disabled={!printerToUse || printLabelIsPending}
                onClick={async () => {
                  setPreviewPngs([]);
                  if (!printerToUse) {
                    return;
                  }
                  const labels = createLabels();
                  if (!labels || labels.length === 0) {
                    return;
                  }

                  const previews = [];
                  for (const labelXml of labels) {
                    previews.push(await getPreview({ printerName: printerToUse.name, labelXml: labelXml }));
                  }

                  setPreviewPngs(previews);
                }}
              >
                Get Preview
              </Button>
              <Button
                size="small"
                type="button"
                variant="primary"
                className="w-full"
                disabled={!printerToUse || printLabelIsPending}
                onClick={async () => {
                  if (!printerToUse) {
                    return;
                  }
                  const labels = createLabels();
                  if (!labels || labels.length === 0) {
                    return;
                  }
                  for (const labelXml of labels) {
                    await printLabel({ printerName: printerToUse.name, labelXml: labelXml });
                  }
                }}
              >
                Print
              </Button>
            </div>
          </div>
        ) : (
          <ErrorAlert title="No Printers Found" />
        )}
      </div>
    </QueryLoaderWrapper>
  );
};
