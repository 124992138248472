import React, { FunctionComponent } from 'react';
import MuxPlayer, { MuxPlayerProps } from '@mux/mux-player-react';

const muxUrlToIdAndTokenRegex = /https:\/\/stream\.mux\.com\/(.*)\.m3u8\?token=(.*)/;

// VideoPlayerProps extends MuxPlayerProps with some of the defaults used to add additional functionality.  Information
// about MuxPlayerProps can be found here:
// https://github.com/muxinc/elements/blob/HEAD/packages/mux-player-react/REFERENCE.md
export interface VideoPlayerProps extends MuxPlayerProps {
  // muxPlaybackUrl is the URL of the video to play.  If specified, muxPlaybackId and muxSignedToken will be ignored.
  // This is a mux specific URL in the format of: https://stream.mux.com/{PLAYBACK_ID}.m3u8?token={SIGNED_TOKEN}
  muxSignedPlaybackUrl?: string;

  // muxPlaybackId is the ID of the video to play.  This is a mux specific ID.
  muxPlaybackId?: string;

  // muxSignedToken is a signed token that allows playback of a specific video.  If present, it will be used along with
  // the muxPlaybackId to play the video.
  muxSignedToken?: string;

  // muted is a flag to indicate if the video should be muted.  Defaults to true.
  muted?: boolean;
}

// VideoPlayer is a thin wrapper around MuxPlayer.  It's here to provide a consistent interface for our components to
// use and may eventually contain extra functionality and styling.  The props are extended from MuxPlayerProps.
export const VideoPlayer: FunctionComponent<VideoPlayerProps> = ({
  muxPlaybackId,
  muxSignedToken,
  muxSignedPlaybackUrl,
  muted = true,
  ...props
}) => {
  if (muxSignedPlaybackUrl) {
    const match = muxSignedPlaybackUrl.match(muxUrlToIdAndTokenRegex);
    if (match) {
      muxPlaybackId = match[1];
      muxSignedToken = match[2];
    }
  }

  let playbackId = muxSignedToken ? `${muxPlaybackId}?token=${muxSignedToken}` : muxPlaybackId;

  // TODO: This (poster={props.poster || ''} is a temporary solution to get around MuxPlayer showing a broken image for
  //  the poster image.  We should use a generated thumbnail from the video instead.
  return (
    <MuxPlayer streamType="on-demand" playbackId={playbackId} muted={muted} {...props} poster={props.poster || ''} />
  );
};
