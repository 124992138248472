import React, { FunctionComponent } from 'react';
import { cn } from '@companion-professional/webutils';
import { UserMenu } from './UserMenu';
import { MobileMenuButton } from './MobileMenuButton';

interface HeaderProps {
  className?: string;
}

// Header is the top of the application main section.  This contains a logo and the user menu.  There is a section
// for adding top navigation elements, but this is currently empty.
export const Header: FunctionComponent<HeaderProps> = ({ className }) => {
  return (
    <nav
      className={cn(
        'flex-no-wrap',
        'relative',
        'flex',
        'w-full',
        'items-center',
        'justify-between',
        'bg-primary-foreground',
        'text-widget',
        'py-2',
        'border-b',
        'border-primary/40',
        'xl:flex-wrap',
        'xl:justify-start',
        className
      )}
    >
      <div className="flex w-full flex-row flex-wrap items-center justify-center px-3">
        <MobileMenuButton />
        <div className="flex max-w-screen-xl flex-1 flex-row justify-end">
          <div className="!visible ml-2 hidden flex-grow basis-[100%] items-center text-primary xl:!flex xl:basis-auto">
            {/* Top of page menu items could/should be added here. */}
          </div>

          <div className="relative flex items-center">
            <div className="relative mr-2" data-te-dropdown-alignment="end">
              <UserMenu />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
