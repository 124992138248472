import React, { FunctionComponent } from 'react';
import { z } from 'zod';
import { DynamicFieldProps } from '../DynamicField';
import { Answers, TextWithSuffixField, TextWithSuffixFieldSchema } from '../dynamicFormSchema';
import { InputWithSuffixSelect } from '../../InputWithSuffixSelect';
import { DynamicFieldComponent } from './DynamicFieldComponentBase';
import { TypeConverterName, typeConverters } from '../typeConverters';

export const InputSuffixOptionSchema = z.object({
  label: z.string(),
  value: z.string()
});

export const InputWithSuffixValueSchema = z.object({
  input: z.union([z.string(), z.number(), z.boolean()]),
  suffix: z.string()
});
export type InputWithSuffixValue = z.infer<typeof InputWithSuffixValueSchema>;

export const TextWithSuffixFieldComponent: FunctionComponent<DynamicFieldProps> = ({ field, reactHookField }) => {
  const ts = TextWithSuffixFieldSchema.parse(field);

  if (reactHookField.value) {
    // Make sure the value is in the correct format
    if (!InputWithSuffixValueSchema.safeParse(reactHookField.value).success) {
      throw new Error('Invalid value for TextWithSuffixField');
    }
  }

  return (
    <InputWithSuffixSelect
      {...ts}
      suffixValue={String(reactHookField.value.suffix)}
      inputValue={String(reactHookField.value.input)}
      onChange={(values) => {
        reactHookField.onChange(values);
      }}
      disabled={reactHookField.disabled}
      inputType={ts.fieldType === 'number' ? 'number' : 'text'}
    />
  );
};

export class DynamicTextWithSuffixField extends DynamicFieldComponent<TextWithSuffixField, InputWithSuffixValue> {
  Component = TextWithSuffixFieldComponent;

  constructor(field: TextWithSuffixField) {
    super(field);
  }

  getAnswer(answers: Answers): InputWithSuffixValue {
    const answer = answers[this.field.name];
    if (answer === undefined) {
      return this.getDefaultValue();
    }

    if (InputWithSuffixValueSchema.safeParse(answer).success) {
      return answer as InputWithSuffixValue;
    }

    throw new Error(`Invalid answer for field ${this.field.name}`);
  }

  getDefaultValue(): InputWithSuffixValue {
    return {
      input: this.field.defaultValue || '',
      suffix: this.field.suffixDefaultValue || ''
    };
  }

  getValueToSubmit(value: InputWithSuffixValue): InputWithSuffixValue {
    const converter = typeConverters[this.field.fieldType as TypeConverterName];
    return {
      input: value?.input ? converter(value?.input) : '',
      suffix: value?.suffix || ''
    };
  }
}
