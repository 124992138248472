import React, { FunctionComponent } from 'react';
import { TextFieldSchema, TextField, Answers } from '../dynamicFormSchema';
import { InputWithLabel } from '../../InputWithLabel';
import { DynamicFieldProps } from '../DynamicField';
import { DynamicFieldComponent } from './DynamicFieldComponentBase';
import { TypeConverterName, typeConverters } from '../typeConverters';

export const TextFieldComponent: FunctionComponent<DynamicFieldProps> = ({ field, reactHookField }) => {
  const t = TextFieldSchema.parse(field);

  return (
    <InputWithLabel
      {...reactHookField}
      // A value must be set to prevent the input from being uncontrolled (an undefined value will result in errors)
      // https://react.dev/reference/react-dom/components/input#im-getting-an-error-a-component-is-changing-an-uncontrolled-input-to-be-controlled
      value={reactHookField.value || ''}
      defaultValue={t.defaultValue}
      id={t.name}
      label={t.label}
      description={t.description}
      name={t.name}
      type={t.fieldType === 'number' ? 'number' : 'text'}
    />
  );
};

export class DynamicTextField extends DynamicFieldComponent<TextField, string | number | boolean | undefined> {
  Component = TextFieldComponent;

  constructor(field: TextField) {
    super(field);
  }

  getAnswer(answers: Answers): string | number | undefined {
    const answer = answers[this.field.name];
    if (typeof answer === 'string' || typeof answer === 'number') {
      return answer;
    }
    return this.getDefaultValue();
  }

  getDefaultValue(): string | number | undefined {
    return this.field.defaultValue || '';
  }

  getValueToSubmit(value: unknown): string | number | boolean | undefined {
    const converter = typeConverters[this.field.fieldType as TypeConverterName];
    return converter(value || '');
  }
}
