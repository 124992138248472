import React, { FunctionComponent, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button } from '../Button';
import { ErrorBoundary } from '../ErrorBoundary';
import { ErrorAlert } from '../ErrorAlert';
import { Field, TypedAnswer } from './dynamicFormSchema';
import { DynamicField } from './DynamicField';
import { getValidationRulesForField } from './validators';
import { LoaderSpinner } from '../LoaderSpinner';
import { DynamicFormProps } from './index';
import { DynamicFieldComponent } from './dynamicFields/DynamicFieldComponentBase';

export type DynamicFormQuestionField = Field;

interface DynamicFormWithDynamicFieldsProps extends DynamicFormProps {
  dynamicFields: DynamicFieldComponent<Field, TypedAnswer>[];
}

// DynamicForm display a list of input fields.
export const DynamicFormDisplay: FunctionComponent<DynamicFormWithDynamicFieldsProps> = ({
  dynamicFields,
  showNumbers,
  onSubmit = () => {},
  isSavePending,
  saveError,
  answers = {},
  showSubmitButton = true,
  disabled = false
}) => {
  const { handleSubmit, control, formState, setValue } = useForm();

  useEffect(() => {
    // Apply all the values from the answers to the form (if they are provided).  This is used to fill out the form
    // for editing or viewing an existing submission.
    dynamicFields.forEach((dynamicField) => {
      if (dynamicField.providesAnswer()) {
        const answerValue = dynamicField.getAnswer(answers);
        if (answerValue !== undefined) {
          setValue(dynamicField.field.name, answerValue);
        }
      }
    });
  }, []);

  return (
    <ErrorBoundary fallback={<ErrorAlert title="Invalid Form" message="Unable to display form elements." />}>
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
        })}
      >
        {/* TODO: the form is currently locked to a width of 800px. This should be removed once all the dynamic fields
              are resizable (I'm looking at you, ImageDiagnosis) */}
        <div className="flex w-[800px] flex-col gap-16">
          {dynamicFields.map((dynamicField, i) => (
            <div key={`field-${dynamicField.field.name}-${i}`} className="flex flex-row">
              {showNumbers ? (
                <div className="px-2 text-base font-semibold leading-none text-secondary">{i + 1}.</div>
              ) : null}
              <Controller
                control={control}
                name={dynamicField.field.name}
                defaultValue={dynamicField.providesAnswer() ? dynamicField.getDefaultValue() : undefined}
                render={({ field: reactHookField }) => (
                  <DynamicField
                    reactHookField={reactHookField}
                    dynamicFieldComponent={dynamicField}
                    formState={formState}
                  />
                )}
                rules={getValidationRulesForField(dynamicField.field)}
                disabled={isSavePending || disabled}
              />
            </div>
          ))}
          {saveError ? <ErrorAlert title="Error Saving Survey" message={saveError} /> : null}
          {isSavePending ? (
            <div className="flex flex-col items-center rounded-lg border border-primary bg-primary/10 p-4">
              <div className="mb-4 text-lg font-bold text-primary">Saving Survey</div>
              <LoaderSpinner size="medium" />
            </div>
          ) : (
            showSubmitButton && (
              <Button name="submit" type="submit">
                Submit
              </Button>
            )
          )}
        </div>
      </form>
    </ErrorBoundary>
  );
};
