import { Field, FIELD_TYPE } from '../dynamicFormSchema';
import { DynamicTextField } from './TextField';
import { DynamicTextWithSuffixField } from './TextWithSuffixField';
import { DynamicCheckboxField } from './CheckboxField';
import { DynamicRadioField } from './RadioField';
import { DynamicImageDiagnosisField } from './ImageDiagnosisField';
import { DynamicShortRangeField } from './ShortRangeField';
import { DynamicCheckboxGroupField } from './CheckboxGroupField';
import { DynamicMarkdownField } from './MarkdownField';
import { DynamicFieldComponent } from './DynamicFieldComponentBase';

// getDynamicFieldComponent returns the correct DynamicFieldComponent based on the field type
// Adding a new component will require:
//  * Adding a new field type to the FIELD_TYPE map in dynamicFormSchema.ts
//  * Creating a new DynamicXField class in this directory
//  * Adding a new case to the switch statement in this function
export function getDynamicFieldComponent(field: Field): DynamicFieldComponent {
  switch (field.type) {
    case FIELD_TYPE.text:
      return new DynamicTextField(field);
    case FIELD_TYPE.textWithSuffix:
      return new DynamicTextWithSuffixField(field);
    case FIELD_TYPE.checkbox:
      return new DynamicCheckboxField(field);
    case FIELD_TYPE.checkboxGroup:
      return new DynamicCheckboxGroupField(field);
    case FIELD_TYPE.radio:
      return new DynamicRadioField(field);
    case FIELD_TYPE.shortRange:
      return new DynamicShortRangeField(field);
    case FIELD_TYPE.imageDiagnosis:
      return new DynamicImageDiagnosisField(field);
    case FIELD_TYPE.markdown:
      return new DynamicMarkdownField(field);
    default:
      // This shouldn't be possible because the field type is validated in the schema, but in case it does happen,
      // throw meaningful error
      // @ts-ignore
      throw new Error(`Unknown field type: ${field?.type as string}`);
  }
}
