// typeConverters is an object that contains functions to convert values to a specific type. A type converter can be
// added to a field with the "fieldType" parameter.  This ensures that the type that's outputted is the desired type
// (e.g. number, boolean, etc.) upon submission.
//
// Note: this only an option on fields that extend the DynamicTypedBasicFieldSchema.
export const typeConverters = {
  string: (value: unknown) => String(value),
  number: (value: unknown) => Number(value),
  boolean: (value: unknown) => Boolean(value),
  yesNoBoolean: (value: unknown) => {
    // Because everything is a string in standard HTML form elements, this boolean converter has logic to handle
    // string values (e.g. 'true' and 'yes' evaluate to true).  This is case-insensitive.
    if (typeof value === 'string') {
      return ['true', 'yes'].includes(value.toLowerCase());
    }
    return false;
  }
} as const;

export type TypeConverterName = keyof typeof typeConverters;
