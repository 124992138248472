import React, { FunctionComponent } from 'react';
import { AlertTriangle } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '../Alert';

interface ErrorAlertProps {
  message?: string;
  title?: string;
}

// ErrorAlert is a component that displays an commonly styled error message.
export const ErrorAlert: FunctionComponent<ErrorAlertProps> = ({ message, title }) => {
  // If there is no message or title, don't show anything.
  if (!message && !title) {
    return null;
  }

  if (typeof message !== 'string') {
    // Even though we have the typescript annotation for message, we should still check it at runtime because it's
    // possible that some 3rd party library could pass in a non-string value (e.g. the Mux UpChunk library seems to on
    // occasion pass an array).
    console.error('ErrorAlert message must be a string', message);
    message = 'An error occurred';
  }

  return (
    <Alert variant="destructive" className="flex min-w-96 max-w-xl flex-row items-center bg-destructive/10">
      <div className="mr-4">
        <AlertTriangle className="h-6 w-6" />
      </div>
      <div>
        {title ? <AlertTitle>{title}</AlertTitle> : null}
        {message ? <AlertDescription>{message}</AlertDescription> : null}
      </div>
    </Alert>
  );
};
