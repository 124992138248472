import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import { jwtDecode } from 'jwt-decode';
import { JwtPayload } from '@companion-professional/dctypes';

// TODO: Maybe migrate this (and the useLogin hook) to it's own library?  I'm not sure what the final shape of this
//  will be, so for now I'm keeping this in the application that it's used in.

export type AuthState = {
  token: string | null;
  email: string | null;
  parsedToken: JwtPayload | null;
  setToken: (token: string) => void;
  setEmail: (email: string) => void;
  setParsedToken: (parsedToken: JwtPayload | null) => void;
  logout: () => void;
};

export const useAuthStore = create(
  // devtools is a middleware that allows use to use the Redux devtools extension.  Note this is only available in
  // development mode.
  devtools(
    // persist the auth state to local storage.
    persist<AuthState>(
      (set) => ({
        token: null,
        parsedToken: null,
        email: null,
        setToken: (token) => {
          if (token && token.length) {
            set({ token, parsedToken: jwtDecode<JwtPayload>(token) });
          }
        },
        setEmail: (email) => {
          set({ email });
        },
        setParsedToken: (parsedToken) => {
          set({ parsedToken });
        },
        logout: () => {
          set({ token: null });
        }
      }),
      {
        // This is the key that the state will be stored under in local storage.
        name: 'vportal-auth',

        // If we are in the password needs reset state, we don't want to persist the token to local storage.  That way,
        // the user will see the login page upon refresh (I think that seeing the perpetual password reset page might
        // be confusing; this prevents that from happening).
        partialize: (state) =>
          state.parsedToken?.password_needs_reset ? { ...state, token: null, parsedToken: null } : state
      }
    )
  )
);

// Some facade functions to make it easier to use the Zustand store and to make it easier to migrate to another state
// management library (if needed).
export const useAuthToken = () => useAuthStore((state) => state.token);
export const useEmail = () => useAuthStore((state) => state.email);
export const useClinicId = () => useAuthStore((state) => state.parsedToken?.clinic_id);
export const useRoles = () => useAuthStore((state) => state.parsedToken?.roles || []);
export const useIsLoggedIn = () => useAuthStore((state) => !!state.token);
export const usePasswordNeedsReset = () => useAuthStore((state) => state.parsedToken?.password_needs_reset || false);
export const useStateLogout = () => useAuthStore((state) => state.logout);
export const useSetToken = () => useAuthStore((state) => state.setToken);
export const useSetEmail = () => useAuthStore((state) => state.setEmail);

// useSetParsedToken sets the parsed token in the store.  This is mostly used for testing.
export const useSetParsedToken = () => useAuthStore((state) => state.setParsedToken);
