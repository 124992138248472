// This is a central place to store configuration details for the application.  Currently, these are hardcoded, but in
// the future we could these could be dependent on the environment that the application is running in.

// API URLS
const API_BASE_URL = '/api/v1';
export const LOGIN_URL = `${API_BASE_URL}/login`;
export const CHANGE_LOGIN_CLINIC_URL = `${API_BASE_URL}/change_login_clinic`;
export const CHANGE_PASSWORD_URL = `${API_BASE_URL}/change_password`;
export const CHECKUP_REQUESTS_URL = `${API_BASE_URL}/checkup_requests`;
export const CHECKUP_REQUESTS_FOR_PET_URL = `${API_BASE_URL}/checkup_requests_for_pet`;
export const CHECKUP_REQUEST_URL = `${API_BASE_URL}/checkup_request`;
export const ARCHIVE_CHECKUP_REQUEST_URL = `${API_BASE_URL}/checkup_requests`;
export const UPDATE_PET_INFO_URL = `${API_BASE_URL}/pet_info`;
export const UPDATE_PET_NAME_URL = `${API_BASE_URL}/pet_name`;
export const GET_PLAYBACK_URL = `${API_BASE_URL}/playback_url`;
export const GET_PETS_URL = `${API_BASE_URL}/pets`;
export const GET_PET_URL = `${API_BASE_URL}/pet`;
export const ARCHIVE_PET_URL = `${API_BASE_URL}/pet`;
export const GET_PET_STATS_URL = `${API_BASE_URL}/pet_stats`;
export const GET_USER_ACCOUNTS_URL = `${API_BASE_URL}/accounts`;
export const GET_SURVEYS_FOR_CLINIC_URL = `${API_BASE_URL}/clinic_surveys`;
export const GET_FULL_SURVEY_URL = `${API_BASE_URL}/clinic_survey`;
export const ADD_SURVEY_ANSWERS_URL = `${API_BASE_URL}/survey_answers`;
export const UPDATE_SURVEY_ANSWERS_URL = `${API_BASE_URL}/survey_answers`;
export const GET_BASE_CHECKUP_URL = `${API_BASE_URL}/base_checkup_url`;
export const GET_SURVEY_ANSWERS_URL = `${API_BASE_URL}/survey_answers`;
export const ADD_CHECKUP_REQUEST_FOR_PET_URL = `${API_BASE_URL}/checkup_request_for_pet`;

// Item Review URLS
const ITEM_REVIEW_BASE_URL = `${API_BASE_URL}/item_review`;
export const GET_ITEMS_TO_REVIEW_FOR_SURVEY_URL = `${ITEM_REVIEW_BASE_URL}/item_survey`;
export const GET_ITEM_REVIEW_SURVEYS_URL = `${ITEM_REVIEW_BASE_URL}/item_surveys`;
export const GET_ITEMS_TO_REVIEW_CHECKUP_SURVEYS_URL = `${ITEM_REVIEW_BASE_URL}/checkup_surveys`;
export const ADD_ITEM_REVIEW_ANSWERS_URL = `${ITEM_REVIEW_BASE_URL}/answers`;
