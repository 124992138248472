import React, { FunctionComponent } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { QueryLoaderWrapper } from '@companion-professional/components';
import { ItemReviewSequenceWrapper } from './ItemReviewSequenceWrapper';
import { ItemsReadyForReview } from './ItemsReadyForReview';
import { PageNotFound } from '../PageNotFound';
import { useItemSurvey } from '../../hooks/useItemSurvey';
import { useItemsToReviewForSurvey } from '../../hooks/useItemsToReviewForSurvey';
import { useCheckupSurveyForItemReview } from '../../hooks/useCheckupSurveyForItemReview';

interface ReviewRoutesProps {}

export const ReviewRoutes: FunctionComponent<ReviewRoutesProps> = () => {
  const { checkupSurveyId, itemReviewSurveyId } = useParams();
  if (!checkupSurveyId || !itemReviewSurveyId) {
    return <PageNotFound />;
  }

  const {
    survey: checkupSurvey,
    error: checkupSurveyError,
    isPending: checkupSurveyIsPending
  } = useCheckupSurveyForItemReview(checkupSurveyId);

  const { itemSurvey, error: itemSurveyError, isPending: itemSurveyIsPending } = useItemSurvey(itemReviewSurveyId);
  const {
    itemsToReviewForSurvey,
    error: itemsError,
    isPending: itemsIsPending
  } = useItemsToReviewForSurvey(itemReviewSurveyId, checkupSurveyId);

  return (
    <QueryLoaderWrapper
      error={itemSurveyError || itemsError || checkupSurveyError}
      isPending={itemSurveyIsPending || itemsIsPending || checkupSurveyIsPending}
    >
      {itemSurvey ? (
        <Routes>
          <Route
            path="/:itemType/:itemId"
            element={
              <ItemReviewSequenceWrapper
                itemSurvey={itemSurvey}
                checkupSurvey={checkupSurvey}
                itemsToReviewIds={(itemsToReviewForSurvey || []).map((i) => i.item_id)}
              />
            }
          />
          <Route
            path="*"
            element={
              <ItemsReadyForReview
                itemSurvey={itemSurvey}
                checkupSurvey={checkupSurvey}
                itemsToReview={itemsToReviewForSurvey}
              />
            }
          />
        </Routes>
      ) : null}
    </QueryLoaderWrapper>
  );
};
