import { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { getErrorMessageFromAxiosError } from '@companion-professional/webutils';
import { useSetToken } from '../state/auth';
import { changeClinicLogin } from '../lib/api';

// useChangeClinic is a hook that returns the state (isPending and errorMessage) and a function for initiating a
// change to the clinic that the user is logged into.
//
// It returns:
// - isPending: A boolean that indicates if the login process is currently in progress.
// - errorMessage: A string that contains an error message if the login process failed.
// - changeClinic: A function that can be called to change the clinic that the user is logged into.
export const useChangeClinic = () => {
  const queryClient = useQueryClient();
  const [isPending, setIsPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const setToken = useSetToken();

  const changeClinic = useCallback((clinicId: string) => {
    setErrorMessage(null);
    setIsPending(true);

    changeClinicLogin(clinicId)
      .then((token) => {
        setToken(token);
        return Promise.all([
          queryClient.invalidateQueries({
            queryKey: ['user-accounts']
          }),
          queryClient.invalidateQueries({
            queryKey: ['active-checkups']
          }),
          queryClient.invalidateQueries({
            queryKey: ['pets-for-clinic']
          })
        ]);
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(getErrorMessageFromAxiosError(err));
      })
      .finally(() => {
        setIsPending(false);
      });
  }, []);

  return {
    isPending,
    errorMessage,
    changeClinic
  };
};
