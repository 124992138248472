import React, { FunctionComponent } from 'react';
import { CardContent } from '@companion-professional/components';

interface CheckupUpCardContentProps {
  children?: React.ReactNode;
}

export const CheckupUpCardContent: FunctionComponent<CheckupUpCardContentProps> = ({ children }) => {
  return <CardContent className="flex flex-1 flex-row items-center justify-center">{children}</CardContent>;
};
