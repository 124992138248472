import { z } from 'zod';

export type DiagnosisImagePoint = {
  name: string;
  label: string;
  x: number;
  y: number;
};

export type DiagnosisImage = {
  name: string;
  imageUrl: string;
  points?: DiagnosisImagePoint[];
};

export type DiagnosisOption = {
  label: string;
  value: string | number;
  color?: string;
};

export const ImageDiagnosisValuesSchema = z.record(
  z.string(),
  z.string().or(z.number().or(z.boolean().or(z.undefined())))
);
export type ImageDiagnosisValues = z.infer<typeof ImageDiagnosisValuesSchema>;
